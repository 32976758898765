<template>
  <div>
    <div class="h-full">
        <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="table_containder relative mt-2 bg-white" v-if="firstLoading">
          <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-6 py-1 items-center " style="position: sticky;top: 0;background: white;z-index: 10;">
            <div class="xsmall_col centerLine p-2 text-center sticky left-1 bg-white" style="min-width:54px;!important">
              <span class="">Index</span>
            </div>
            <div class="col_col centerLine sticky bg-white" style="left: 58px !important;">
              <span class="cursor-pointer" @click="sortByColumn(0, titleObj[0].sort, titleObj[0].value)">{{titleObj[0].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[0].value) && titleObj[0].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[0].value) && !titleObj[0].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="col_col centerLine sticky bg-white" style="left: 141px !important;">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(1, titleObj[1].sort, titleObj[1].value)">{{titleObj[1].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="payment_col centerLine sticky bg-white" style="left: 224px !important;">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn( 2, titleObj[2].sort, titleObj[2].value)">{{titleObj[2].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="date_col  centerLine sticky bg-white" style="left: 323px !important;">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(3, titleObj[3].sort, titleObj[3].value)">{{titleObj[3].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[3].value) && titleObj[3].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[3].value) && !titleObj[3].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="val_col  centerLine sticky bg-white" style="left: 468px !important;">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(4, titleObj[4].sort, titleObj[4].value)">{{titleObj[4].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[4].value) && titleObj[4].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[4].value) && !titleObj[4].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="val_col  centerLine sticky bg-white" style="left: 563px !important;">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(5, titleObj[5].sort, titleObj[5].value)">{{titleObj[5].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[5].value) && titleObj[5].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[5].value) && !titleObj[5].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="small_col2  centerLine sticky bg-white" style="left: 658px !important;">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(6, titleObj[6].sort, titleObj[6].value)">{{titleObj[6].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[6].value) && titleObj[6].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[6].value) && !titleObj[6].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="val_col  centerLine sticky bg-white" style="left: 768px !important;">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(7, titleObj[7].sort, titleObj[7].value)">{{titleObj[7].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[7].value) && titleObj[7].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[7].value) && !titleObj[7].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="val_col  centerLine sticky bg-white" style="left: 862px !important;">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(8, titleObj[8].sort, titleObj[8].value)">{{titleObj[8].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[8].value) && titleObj[8].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[8].value) && !titleObj[8].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="small_col  centerLine">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(9, titleObj[9].sort, titleObj[9].value)">{{titleObj[9].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[9].value) && titleObj[9].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[9].value) && !titleObj[9].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="small_col  centerLine">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(10, titleObj[10].sort, titleObj[10].value)">{{titleObj[10].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[10].value) && titleObj[10].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[10].value) && !titleObj[10].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="small_col  centerLine">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(11, titleObj[11].sort, titleObj[11].value)">{{titleObj[11].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[11].value) && titleObj[11].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[11].value) && !titleObj[11].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="small_col  centerLine">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(12, titleObj[12].sort, titleObj[12].value)">{{titleObj[12].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[12].value) && titleObj[12].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[12].value) && !titleObj[12].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="small_col  centerLine">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(13, titleObj[13].sort, titleObj[13].value)">{{titleObj[13].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[13].value) && titleObj[13].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[13].value) && !titleObj[13].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
            <div class="small_col  centerLine">
              <span class="cursor-pointer overFlowParaA" @click="sortByColumn(14, titleObj[14].sort, titleObj[14].value)">{{titleObj[14].title}}</span>
              <span v-if="(pagination.sortBy === titleObj[14].value) && titleObj[14].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
              <p v-if="(pagination.sortBy === titleObj[14].value) && !titleObj[14].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
            </div>
          </div>
          <div :style="`max-height:` +  cardHight + `px; !important`" class="">
            <div class="min-w-min flex border-r border-l border-b border-gray1 bg-white text-sm items-center py-1 cursor-pointer "  v-for="(value, indexA) in ExtFListingObj" :key="indexA">
              <div class="xsmall_col sticky left-1 bg-white text-center">
                <span class="heading-6-1 text-text1">{{indexA + 1}}</span>
              </div>
              <div class="col_col sticky bg-white" style="left: 58px !important;">
                <span class="heading-6-1 text-text1">{{value.orderId !== '' ? value.orderId : '-'}}</span>
              </div>
              <div class="col_col sticky bg-white " style="left: 141px !important;">
                <span class="heading-6 text-text1">{{value.orderStatus !== '' ? value.orderStatus : '-'}}</span>
              </div>
              <div class="payment_col sticky bg-white " style="left: 224px !important;">
                <span class="heading-6 text-text1 overFlowParaA">{{value.paymentMethod !== '' ? value.paymentMethod : '-'}}</span>
              </div>
              <div class="date_col sticky bg-white " style="left: 323px !important;">
                <span class="heading-6 text-text1 overFlowParaA">{{value.postDate | dateTimeFilterA}}</span>
              </div>
              <div class="val_col sticky bg-white " style="left: 468px !important;">
                <span class="heading-6 text-text1">{{value.firstName !== '' ? value.firstName : '-'}}</span>
              </div>
              <div class="val_col sticky bg-white " style="left: 563px !important;">
                <span class="heading-6 text-text1">{{value.lastName !== '' ? value.lastName : '-'}}</span>
              </div>
              <div class="small_col2 sticky bg-white " style="left: 658px !important;">
                <span class="heading-6-1 text-text1 overFlowParaA">{{value.city !== '' ? value.city : '-'}}</span>
              </div>
              <div class="val_col sticky bg-white " style="left: 768px !important;">
                <span class="heading-6-1 text-text1">{{value.state !== '' ? value.state : '-'}}</span>
              </div>
              <div class="val_col sticky bg-white " style="left: 862px !important;">
                <span class="heading-6-1 text-text1">{{value.postcode !== '' ? value.postcode : '-'}}</span>
              </div>
              <div class="small_col rightText">
                <span class="heading-6-1 text-text1">{{value.cartDiscount | amountFormaterWithToFix}}</span>
              </div>
              <div class="small_col rightText">
                <span class="heading-6-1 text-text1">{{value.subTotal | amountFormaterWithToFix}}</span>
              </div>
              <div class="small_col rightText">
                <span class="heading-6-1 text-text1">{{value.orderShipping | amountFormaterWithToFix}}</span>
              </div>
              <div class="small_col rightText">
                <span class="heading-6-1 text-text1">{{value.orderTax | amountFormaterWithToFix}}</span>
              </div>
              <div class="small_col rightText">
                <span class="heading-6-1 text-text1">{{value.orderRefund | amountFormaterWithToFix}}</span>
              </div>
              <div class="small_col rightText">
                <span class="heading-6-1 text-text1">{{value.orderTotal | amountFormaterWithToFix}}</span>
              </div>
            </div>
          </div>
          <div class="layout m-2" v-if="ExtFListingObj.length === 0 && firstLoading">
          <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
            <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Info</span>
            <div>
              <p class=" font-medium heading-4">No record found.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import deboucneMixin from '@/mixins/debounce.js'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
  },
  mixins: [deboucneMixin],
  data () {
    return {
      titleObj: [
        {title: 'Order ID', icon: '', value: 'orderId', sort: true},
        {title: 'Type', icon: '', value: 'orderStatus', sort: true},
        {title: 'Payment Method', icon: '', value: 'paymentMethod', sort: true},
        {title: 'Date/Time', icon: '', value: 'postDate', sort: true},
        {title: 'First Name', icon: '', value: 'firstName', sort: true},
        {title: 'Last Name', icon: '', value: 'lastName', sort: true},
        {title: 'Order City', icon: '', value: 'city', sort: true},
        {title: 'Order State', icon: '', value: 'state', sort: true},
        {title: 'Order Postal', icon: '', value: 'postcode', sort: true},
        {title: 'Cart Discount Amount', icon: '', value: 'cartDiscount', sort: true},
        {title: 'Order Subtotal Amount', icon: '', value: 'subTotal', sort: true},
        {title: 'Order Shipping Amount', icon: '', value: 'orderShipping', sort: true},
        {title: 'Order Total Tax Amount', icon: '', value: 'orderTax', sort: true},
        {title: 'Order Refund Amount', icon: '', value: 'orderRefund', sort: true},
        {title: 'Order Total Amount', icon: '', value: 'orderTotal', sort: true},
      ],
      pagination: {
        sortBy: 'amazonSKU',
        descending: true
      },
      cardHight: 0,
      mainHeight: 0,
    }
  },
  props: ["firstLoading", "ExtFListingObj", "startDate", "endDate"],
  created() {
  },
  mounted () {
    this.cardHight = window.innerHeight - 255
    this.mainHeight = window.innerHeight - 145
  },
  methods: {
    sortByColumn (i, val1, val2) {
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      console.log('this.pagination.descending', this.pagination.descending)
      if (this.pagination.descending) {
        console.log('click 1')
        this.ExtFListingObj.sort(function (a, b) {
          if (val2 === 'orderId') {
            var nameA = a.orderId
            var nameB = b.orderId
          } else if (val2 === 'orderStatus') {
            nameA = a.orderStatus
            nameB = b.orderStatus
          } else if (val2 === 'paymentMethod') {
            nameA = a.paymentMethod
            nameB = b.paymentMethod
          } else if (val2 === 'postDate') {
            nameA = a.postDate
            nameB = b.postDate
          } else if (val2 === 'firstName') {
            nameA = a.firstName
            nameB = b.firstName
          } else if (val2 === 'lastName') {
            nameA = a.lastName
            nameB = b.lastName
          } else if (val2 === 'city') {
            nameA = a.city
            nameB = b.city
          } else if (val2 === 'state') {
            nameA = a.state
            nameB = b.state
          } else if (val2 === 'postcode') {
            nameA = a.postcode
            nameB = b.postcode
          } else if (val2 === 'cartDiscount') {
            nameA = a.cartDiscount
            nameB = b.cartDiscount
          } else if (val2 === 'subTotal') {
            nameA = a.subTotal
            nameB = b.subTotal
          } else if (val2 === 'orderShipping') {
            nameA = a.orderShipping
            nameB = b.orderShipping
          } else if (val2 === 'orderTax') {
            nameA = a.orderTax
            nameB = b.orderTax
          } else if (val2 === 'cartDiscount') {
            nameA = a.cartDiscount
            nameB = b.cartDiscount
          } else if (val2 === 'orderTotal') {
            nameA = a.orderTotal
            nameB = b.orderTotal
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        console.log('click 2')
        this.ExtFListingObj.reverse(function (a, b) {
          if (val2 === 'orderId') {
            var nameA = a.orderId
            var nameB = b.orderId
          } else if (val2 === 'orderStatus') {
            nameA = a.orderStatus
            nameB = b.orderStatus
          } else if (val2 === 'paymentMethod') {
            nameA = a.paymentMethod
            nameB = b.paymentMethod
          } else if (val2 === 'postDate') {
            nameA = a.postDate
            nameB = b.postDate
          } else if (val2 === 'firstName') {
            nameA = a.firstName
            nameB = b.firstName
          } else if (val2 === 'lastName') {
            nameA = a.lastName
            nameB = b.lastName
          } else if (val2 === 'city') {
            nameA = a.city
            nameB = b.city
          } else if (val2 === 'state') {
            nameA = a.state
            nameB = b.state
          } else if (val2 === 'postcode') {
            nameA = a.postcode
            nameB = b.postcode
          } else if (val2 === 'cartDiscount') {
            nameA = a.cartDiscount
            nameB = b.cartDiscount
          } else if (val2 === 'subTotal') {
            nameA = a.subTotal
            nameB = b.subTotal
          } else if (val2 === 'orderShipping') {
            nameA = a.orderShipping
            nameB = b.orderShipping
          } else if (val2 === 'orderTax') {
            nameA = a.orderTax
            nameB = b.orderTax
          } else if (val2 === 'cartDiscount') {
            nameA = a.cartDiscount
            nameB = b.cartDiscount
          } else if (val2 === 'orderTotal') {
            nameA = a.orderTotal
            nameB = b.orderTotal
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
  },
  destroyed() {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.val_col {
  min-width: 95px !important;
  width: 95px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.payment_col {
  min-width: 100px !important;
  width: 100px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.date_col {
  min-width: 150px !important;
  width: 150px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.col_col {
  min-width: 80px !important;
  width: 80px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col2 {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  min-width: 179px !important;
  width: 179px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
  justify-content: right;
}
.overFlowParaA {
  /* word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block; */
}

.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.loader2 {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #0636c7;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.9s linear infinite;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
