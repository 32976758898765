<template>
  <div>
    <div class="h-full" v-if="ExtFListingObj !== null">
      <p class="p-2 heading-3 text-text1">Reconciliation</p>
      <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="table_containder relative mt-2" v-if="firstLoading">
        <div :style="`max-height:` +  cardHight + `px; !important`" class="pt-2">
          <div class="min-w-min flex p-2 text-sm items-center py-1   w-96">
            <div class="dates_col hover:bg-gray-50">
              <span class="heading-5 text-text1">Gross Revenue Amount</span>
            </div>
            <div class="small1_col hover:bg-gray-50 text-right">
              <span class="heading-5 text-text2">{{ExtFListingObj.totalGrossRevenue | amountFormaterWithToFix}}</span>
            </div>
          </div>
          <div class="min-w-min flex p-2 text-sm items-center py-1  bg-gray-50 w-96">
            <div class="dates_col hover:bg-gray-50">
              <span class="heading-5 text-text1">Refund Amount</span>
            </div>
            <div class="small1_col hover:bg-gray-50 text-right">
              <span class="heading-5 text-text2">{{ExtFListingObj.orderRefundAmt | amountFormaterWithToFix}}</span>
            </div>
          </div>
          <div class="min-w-min flex p-2 text-sm items-center py-1   w-96">
            <div class="dates_col hover:bg-gray-50">
              <span class="heading-5 text-text1">Shipping Amount</span>
            </div>
            <div class="small1_col hover:bg-gray-50 text-right">
              <span class="heading-5 text-text2">{{ExtFListingObj.orderShippingAmt | amountFormaterWithToFix}}</span>
            </div>
          </div>
          <div class="min-w-min flex p-2 text-sm items-center py-1  bg-gray-50 w-96">
            <div class="dates_col hover:bg-gray-50">
              <span class="heading-5 text-text1">Tax Amount</span>
            </div>
            <div class="small1_col hover:bg-gray-50 text-right">
              <span class="heading-5 text-text2">{{ExtFListingObj.orderTotlaTaxAmt | amountFormaterWithToFix}}</span>
            </div>
          </div>
          <div class="min-w-min flex p-2 text-sm items-center py-1  w-96">
            <div class="dates_col hover:bg-gray-50">
              <span class="heading-5 text-text1">Net Amount</span>
            </div>
            <div class="small1_col hover:bg-gray-50 text-right">
              <span class="heading-5 text-text2">{{ExtFListingObj.revenue | amountFormaterWithToFix}}</span>
            </div>
          </div>
          <div class="dividerLight w-96"></div>
        </div>
      </div>
      <div class="layout m-2" v-if="ExtFListingObj === null && firstLoading">
        <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
            <p class=" font-medium heading-4">No record found.</p>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import deboucneMixin from '@/mixins/debounce.js'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
  },
  mixins: [deboucneMixin],
  data () {
    return {
      pagination: {
        sortBy: 'date',
        descending: true
      },
      cardHight: 0,
      mainHeight: 0,
    }
  },
  created() {
  },
  props: ["firstLoading", "ExtFListingObj", "startDate", "endDate"],
  mounted () {
    this.cardHight = window.innerHeight - 255
    this.mainHeight = window.innerHeight - 145
  },
  destroyed() {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.val_col {
  min-width: 95px !important;
  width: 95px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.payment_col {
  min-width: 100px !important;
  width: 100px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.date_col {
  min-width: 130px !important;
  width: 130px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.col_col {
  min-width: 80px !important;
  width: 80px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.dates_col {
  min-width: 240px !important;
  width: 240px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.small1_col {
  min-width: 134px !important;
  width: 134px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.loader2 {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #0636c7;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.9s linear infinite;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
